#usuarios{
    .usuario{
        border-radius: 1em;
        margin: 0.5em 1em;
        align-items: center;

        .icon-star{
            color: gold;
            margin: auto;
            margin-left: 3px;
        }

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        .md-button.md-no-style{
            border-radius: 1em;
        }
    }
}

#editarUsuario{
    md-dialog-content{
        padding: 24px;

        .icon-star{
            margin: auto;
            margin-right: 8px;
            cursor: pointer;
        }

        .star-active{
            color: gold;            
        }
    }
}