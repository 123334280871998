#actions{    
    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;

            &:after {
                position: relative;
                font-family: 'icomoon';
                font-weight: normal;
                margin-left: 10px;
                top: 2px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .ord-no {

            &:after {
                content: '\eaa8';
            }
        }

        .ord-asc{

            &:after {
                content: '\e718';
            }
        }

        .ord-desc{

            &:after {
                content: '\e715';
            }
        }
    }
}

#editarAction{
    md-dialog-content {
        padding: 0;

        .menu{

            .menu-element {
                font-size: 14px;
                padding: 12px 8px;
                white-space: nowrap;
                cursor: pointer;

                &.selected {
                    background-color: orangered;
                }

                md-icon {
                    margin-right: 0;
                }
            }
        }

        .content {
            overflow: auto;
            padding: 18px;

            md-checkbox{
                margin-bottom: 4px;

                span{
                    word-break: break-word;
                }

                .md-container:after{
                    top: -1px;
                    bottom: -1px;
                    left: -1px;
                    right: -1px;
                }
            }
            
            .text-boxed {
                white-space: normal;
            }
        }
    }
}