#places{
    .place{
        border-radius: 1em;
        margin: 0.5em 1em;
        padding: 0 56px 0 0;
        align-items: center;
        
        .md-list-item-text{
            h3, h4, span {
                margin: 8px 0;
            }
        }

        md-fab-speed-dial{
            position: absolute;
            right: 0;
            top: 0;

            &.md-is-open{
                md-fab-trigger{
                    opacity: 0.2;
                }
            }
        }

        .indicador{
            margin: 0 4px;

            .icon-sync, .icon-sync-alert{
                font-size: 36px;
                width: 36px;
                height: 36px;
                line-height: 36px;
            }

            .icon-sync{
                color: green;
            }

            .icon-sync-alert{
                color: red;
            }
        }
    }
}

#place-cards{
    .buscador{
        md-input-container{
            margin: 0;

            .md-errors-spacer{
                display: none;
            }
        }

        .pagination-button{
            min-width: 36px;
            font-size: 30px;
            padding: 0;
        }

        >div:first-child{
            border-radius:3em;

            input{
                border-radius: 10px 3em 3em 10px;
                background-color: #f7f7f7; 
            }

            md-icon{
                color: white;
                margin-left: 5px;
            }
        }

        span{
            margin-left: 3px;
            margin-right: 8px;
        }
    }

    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }
        
        tr:last-of-type td {
            border-bottom: 1px solid gray;
        }

        .column-title {
            cursor: pointer;

            &:after {
                position: relative;
                font-family: 'icomoon';
                font-weight: normal;
                margin-left: 10px;
                top: 2px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .ord-no {

            &:after {
                content: '\eaa8';
            }
        }

        .ord-asc{

            &:after {
                content: '\e718';
            }
        }

        .ord-desc{

            &:after {
                content: '\e715';
            }
        }
    }

    md-select{
        transition:background-color 0.5s;
        background-color: #f7f7f7; 
    }

    textarea {
        width: 100%;
        resize: vertical;
        background-color: #f7f7f7;
        min-height: 200px;
    }
    
    .card-row {
        md-checkbox {
            margin: 8px 0;
        }
    }
}