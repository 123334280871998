/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/common-style.scss";
@import "toolbar/toolbar.scss";
@import "main/actions/actions.scss";
@import "main/cards/cards.scss";
@import "main/places/places.scss";
@import "main/usuarios/usuarios.scss";
@import "main/auth/login/login.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "main/auth/errores/404/error-404.scss";
@import "main/auth/errores/500/error-500.scss";
// endinjector